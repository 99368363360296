import { SoftwarePackageV2 } from '@models/softwarePackge';
import StopWatch from '@images/SotwarePackages/stop-watch.svg';
import LockShield from '@images/SotwarePackages/lock-shield.svg';
import ExpandArrows from '@images/SotwarePackages/expand-arrows.svg';
import Seatbelt from '@images/SotwarePackages/seatbelt.svg';
import TrafficLight from '@images/SotwarePackages/traffic-light.svg';
import GearMagnifyingGlass from '@images/SotwarePackages/gear-magnifying-glass.svg';
import Lightbulb from '@images/SotwarePackages/lightbulb.svg';
import Leaf from '@images/SotwarePackages/leaf.svg';
import Warranty from '@images/SotwarePackages/warranty.svg';
import Wifi from '@images/SotwarePackages/wifi.svg';
import Device from '@images/SotwarePackages/device.svg';
import Scale from '@images/SotwarePackages/scale.svg';
import Usage from '@images/SotwarePackages/usage.svg';
import Support from '@images/SotwarePackages/support.svg';

export const enSoftwarePackages: SoftwarePackageV2 = {
    version: 2,
    seo: {
        title: 'Software Packages | Geotab',
        description: 'Compare features and choose your desired plan for telematics and fleet management.',
    },
    getPrices: 'Get prices',
    header: {
        title: 'Our most comprehensive package built to meet your needs',
        description:
            'Geotab empowers your fleet by delivering tangible outcomes that support your operational goals and drive long-term value. Through deep data insights and AI, we enable you to make informed decisions to improve performance, safety, sustainability and cost management.',
        featured: [
            'Unparalleled breadth, quality, and depth of data ',
            'Flexible customization and scalability',
            'Secure platform ensuring your data is safe',
            'Patented algorithm for high-quality data capture',
            'Built-in AI and predictive analytics models',
            'Personalized support 24/7',
        ],
        cta: {
            bannerText: 'Unleash full telematics value with the GO Plan',
            title: 'GO Plan',
            subtitle: 'Worldwide',
            description:
                'Designed to offer enhanced data, more comprehensive insights, and expanded compatibility with a broader range of products, the GO Plan is a single plan that offers max value to customers.',
        },
    },
    goPlanBenefits: {
        title: 'Features',
        subtitle: 'Get Access to Every Feature from Your GO Device with the GO Plan',
        description:
            'Eliminate the complexity of subscription management and get comprehensive access to empower decisions for fleet management and operational efficiencies.',
        features: [
            {
                title: 'All-in-One Telematics Subscription',
                description:
                    'No tiers so you can experience everything the platform has to offer. Benefit from a streamlined plan with less admin and more insights.',
                icon: Device,
            },
            {
                title: 'Scale Value Without Restrictions',
                description:
                    'Effortlessly scale your usage of advanced features and data intelligence as your business grows.',
                icon: Scale,
            },
            {
                title: 'Wi-Fi Support',
                description:
                    'Includes 2GB of monthly data connectivity with the GO9+ device for seamless online access.',
                icon: Wifi,
            },
            {
                title: 'Learn As Your Usage Grows',
                description:
                    'Master the entire platform with ease.  Access in-app guidance and tutorials to maximize the ROI from your telematics investment.',
                icon: Usage,
            },
            {
                title: 'Access to Experts',
                description: 'Your business is always on, and so is our support.  Gain access to experts 24 x 7 x 365.',
                icon: Support,
            },
            {
                title: 'Limited Lifetime Device Warranty',
                description:
                    'Protects against device failure costs, providing peace of mind with a worry-free warranty.',
                icon: Warranty,
            },
        ],
    },
    categories: [
        {
            title: 'Productivity',
            icon: StopWatch,
            features: [
                'Instant GPS tracking using patented curve based algorithms for optimal recording',
                'Highly Flexible Rules and Exception Engine - customizable and editable',
                'Driver congregation reporting',
                'Locating closest vehicle by address',
                'Driver ID via NFC',
            ],
        },
        {
            title: 'Safety',
            icon: Seatbelt,
            features: [
                'Basic driver coaching - Audible alerts and notifications for speeding, idling, and driver identification',
                'Basic driver coaching and customizable server-side rules and exceptions',
                'Advanced driver coaching - audible alerts and notifications for harsh braking, sharp cornering, over-acceleration, seat belt use, vehicle in reverse, over-revving',
                'New Major and Minor Collision Detection',
                'Safety Center: Predictive Risk Analytics and Benchmarking',
            ],
        },
        {
            title: 'Fleet Optimization',
            icon: GearMagnifyingGlass,
            features: [
                'Vehicle maintenance reminders scheduled by time or distance',
                'Vehicle maintenance reminders scheduled by mileage or engine hours',
                'Easy device installation with Self-Calibrating Accelerometer',
                'Engine status data for all major engine protocols (fuel usage/voltage/coolant/temp.)',
                'Record and interpret fault data (J1939, 1708, OBD, CAN)',
                'Geotab Roadside Add-In for complimentary towing, locksmith, battery boost, fuel delivery, flat tire changes, & more',
                'Fuel Management Platform — track fuel ups, fuel drain, fuel theft, fuel card integration',
            ],
        },
        {
            title: 'Expandability',
            icon: ExpandArrows,
            features: [
                'Web-based software reporting platform (SAAS) capable of supporting unlimited vehicled and users',
                'Support of multiple map types',
                'Rich reporting functionality - editable, customizable, trends',
                'Customizable dashboards',
                'Flexible user access control',
                'MyGeotab access via Android and iOS',
                'SDK and APIs that are open, free, and easy to use',
            ],
        },
        {
            title: 'Compliance',
            icon: TrafficLight,
            features: [
                'Device tampering detection',
                'Driver Application (Geotab Drive) scalable for future needs',
                'HOS compliance via Geotab Drive (Android/iOS)',
                'DVIR compliance via Geotab Drive (Android/iOS)',
                'IFTA miles recording',
                'Privacy Mode',
            ],
        },
        {
            title: 'Sustainability',
            icon: Leaf,
            features: [
                'Electric Vehicle Suitability Assessment (EVSA)',
                'Electric Vehicle Infrastructure Assessment (EVIA)',
                'EV driving energy used',
                'EV battery charge % (SOC)',
                'EV charging energy',
                'EV charging status',
            ],
        },
        {
            title: 'Innovation',
            icon: Lightbulb,
            features: ['Generative AI', 'Routing & Optimization', 'Geotab Data Connector'],
        },
        {
            title: 'Privacy & Security',
            icon: LockShield,
            features: [
                'A responsible framework for privacy-focused outcomes',
                'FIPS 140-2 Validation',
                'FedRAMP Authorization',
                'ISO-IEC 27001:2013',
                'UNECE 155/156',
                'Trusted by many of the world\'s most security conscious organizations',
            ],
        },
    ],
};
